<template>
  <section class="section section__hero in-view">
    <div class="section__hero__bg">
      <div class="section__hero__bg__back">
        <img src="@/assets/images/office_backdrop.jpg" alt="background" />
      </div>
      <div class="section__hero__bg__front_rain"></div>
      <div class="section__hero__bg__front">
        <img src="@/assets/images/Rellax_Office-wide.png" alt="foreground" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.section__hero {
    position: relative;
    overflow: hidden;
    height: 35vw; /* You can set this to the height you want */
}

.section__hero__bg__back, .section__hero__bg__front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.section__hero__bg__front {
    z-index: 3;
}

.section__hero__bg__back {
    z-index: 1;
}

.section__hero__bg__front_rain {
    background-image: url("@/assets/images/rain.gif");
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

@media (max-width: 767px) {
    h1 {
        line-height: 1.2;
        font-size: 2rem;
        text-align: left;
    }
    .font-general-medium {
        text-align: left;
    }
    .flex-col {
        flex-direction: column-reverse;
    }
}
</style>
