import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
const feather = require('feather-icons');

// Add icons to the library
library.add(fas, fab);

// Create and configure app
const app = createApp(App);

// Register global components and use plugins
app.component('fa', FontAwesomeIcon);
app.use(router);
app.use(store);
app.use(BackToTop);

// Mount the app
app.mount('#app');

// Replace feather icons
feather.replace();

// Global function for star creation
app.config.globalProperties.$createStars = function() {
    const starsContainer = document.getElementById('stars-container');
    for (let i = 0; i < 200; i++) {
        const star = document.createElement('div');
        const size = Math.random() * 2;
        const positionTop = Math.random() * window.innerHeight;
        const positionLeft = Math.random() * window.innerWidth;
        const animationDuration = Math.random() * 3 + 2;
  
        star.style.position = 'absolute';
        star.style.backgroundColor = 'white';
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        star.style.top = `${positionTop}px`;
        star.style.left = `${positionLeft}px`;
        star.style.opacity = 0.8;
        star.style.animation = `move-stars ${animationDuration}s linear infinite`;
  
        starsContainer.appendChild(star);
    }
};

// Apply theme based on local storage
const appTheme = localStorage.getItem('theme');
if (appTheme === 'dark' && document.querySelector('body').classList.contains('app-theme')) {
    document.querySelector('body').classList.add('bg-primary-dark');
} else {
    document.querySelector('body').classList.add('bg-secondary-light');
}
