<template>
	<div class="grad">
	<div id="stars-container"></div>
  
	<AppHeader />
	<!-- Banner -->
	<AppBanner class="mb-5 sm:mb-8" />
  
	<!-- Custom Area -->
	<!-- services vue -->
	<Services />
	</div>
	<div class="grad-2">
	<div class="container mx-auto">
		<!-- services vue end-->
  
		<!-- Projects -->
		<ProjectsGrid />
  
		<!-- Load more projects button -->
		<div class="mt-10 sm:mt-20 flex justify-center">
		<router-link
			to="/projects"
			class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
			aria-label="More Projects"
		>
			<Button title="More Projects" />
		</router-link>
		</div>
	</div>

	<div class="grad-3">
	<div class="container mx-auto">
		<section class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10" style="padding-top:20%">
		<!-- About Luke Left -->
		<div class="w-full md:w-1/2 text-left">
			<h1
			class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light fadeInUp"
			data-te-animation-start="onLoad"
			>
			<span>About Luke</span>
			</h1>
			<p
			class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400"
			>
			I'm an independent web designer and developer. For the past decade, I've been dedicated to breaking new ground
			in the realm of digital design.
			</p>
			<div class="flex justify-center sm:block">
			<a
				download=""
				href="https://lukeellis.me"
				target="_blank"
				class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
				aria-label="About Luke"
			>
				<i
				data-feather="arrow-down-circle"
				class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
				></i>
				<span class="text-sm sm:text-lg font-general-medium duration-100">More about me</span>
			</a>
			</div>
		</div>
  
		<!-- Banner right illustration -->
		<div class="w-full md:w-1/3 text-right float-right" style="display:flex; justify-content: center;">
			<img
			v-if="theme === 'light'"
			src="/img/profile.5912990e.jpeg"
			alt="Developer"
			style="border-radius: 25px; animation: fade-up 1s ease-in-out;"
			class="animate__animated animate__fadeInUp"
			/>
			<img
			v-else
			src="/img/profile.5912990e.jpeg"
			alt="Developer"
			style="border-radius: 25px; animation: fade-up 1s ease-in-out;"
			class="animate__animated animate__fadeInUp"
			/>
		</div>
		</section>
	</div>
	
</div>
</div>
	<FAQAccordion />
	<RellaxOffice />
  </template>
  
  <script>
  import AppHeader from '@/components/shared/AppHeader.vue';
  import AppBanner from '@/components/shared/AppBanner.vue';
  import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
  import Button from '../components/reusable/Button.vue';
  import Services from '@/components/shared/Services.vue';
  import FAQAccordion from '@/components/shared/FAQAccordion.vue';
  import RellaxOffice from '@/components/RellaxOffice.vue';
  
  
  export default {
	name: 'Home',
	components: {
	AppHeader,
	AppBanner,
	ProjectsGrid,
	RellaxOffice,
	Button,
	FAQAccordion,
	Services
	},
	mounted() {
	this.$nextTick(() => {
		this.createStars();
	});
	},
	methods: {
	createStars() {
		const starsContainer = document.getElementById('stars-container');
  
		for (let i = 0; i < 200; i++) {
		const star = document.createElement('div');
  
		const size = Math.random() * 2;
		const positionTop = Math.random() * window.innerHeight;
		const positionLeft = Math.random() * window.innerWidth;
		const animationDuration = Math.random() * 3 + 2;
  
		star.style.position = 'absolute';
		star.style.backgroundColor = 'white';
		star.style.width = `${size}px`;
		star.style.height = `${size}px`;
		star.style.top = `${positionTop}px`;
		star.style.left = `${positionLeft}px`;
		star.style.opacity = 0.8;
		star.style.animation = `move-stars ${animationDuration}s linear infinite`;
  
		starsContainer.appendChild(star);
		}
	}
	}
  };
  </script>
  
  <style scoped>
  .grad {
	position: relative;
	overflow: hidden;
	background-image: linear-gradient(180deg, #39007D 0%, #BC329A 40%, #FF6373 80%);
  }
  
  .object {
	animation: MoveUpDown 1s linear infinite;
	position: absolute;
	left: 0;
	bottom: 0;
  }
  
  @keyframes MoveUpDown {
	0%, 100% {
	bottom: 0;
	}
	50% {
	bottom: 100px;
	}
  }
  
  .sun {
	position: absolute;
	bottom: 10%;
	left: 50%;
	width: 100px;
	height: 100px;
	background: radial-gradient(circle, #FFD700, #FF8C00);
	border-radius: 50%;
	z-index: 3;
	transition: all 2s;
  }
  </style>
  