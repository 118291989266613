<template>
  <div class="container mx-auto">
    <h2 class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light fadeInUp" style="padding: 10% 2%; text-align: center;">Frequently Asked Questions</h2>
    <div x-data="{ openItem: null }" style="padding: 2% 15% 20% 15%;">
      <div class="faq-item" v-for="(item, index) in items" :key="index" style="margin: 2% 0;">
        <button @click="item.open = !item.open" class="faq-toggle w-full flex items-center justify-between p-4 my-6 text-left rounded-lg bg-white shadow">
          <span class="font-semibold">{{ item.question }}</span>
          <i :class="{'bi-chevron-down': !item.open, 'bi-chevron-up': item.open}" class="bi bi-chevron-down"></i>
        </button>
        <div
          v-show="item.open"
          class="faq-content overflow-hidden transition-all ease-in-out duration-500"
          :style="{ maxHeight: item.open ? `${item.contentHeight}px` : '0', transition: 'max-height 0.5s ease' }"
        >
          <div ref="content" class="p-4 pt-0 text-left">
            <p>{{ item.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQAccordion',
  data() {
    return {
      items: [
        {
          question: 'What services do you provide as a freelance web designer?',
          answer: 'As a freelance web designer, I offer a range of services including website design, responsive design, website maintenance and hosting, and content management system (CMS) integration. I can also help with search engine optimisation (SEO) and e-commerce solutions.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'Can you redesign or update my existing website?',
          answer: 'Regrettably, I do not work on existing websites. Each project demands a unique and tailored approach to achieve optimal outcomes. By building a new website from the ground up, I can thoroughly consider your brand, business goals, and target audience. This ensures the final website not only boasts a visually appealing design but also performs seamlessly for both you and your visitors.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'What is your web design process like?',
          answer: 'My web design process is designed to deliver a seamless experience and ensure a high-quality, custom website that meets your specific needs. The process generally involves initial consultation to discuss your goals, requirements, and preferences; developing a proposal and agreement based on our discussion; researching and planning to establish a solid plan for your website\'s structure, design, and functionality; creating design mockups for review and revisions; developing the website by implementing the desired features and functionality; testing and quality assurance to ensure the website works correctly across various devices and browsers; and finally, launching the website and providing post-launch support as needed.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'How much do you charge for your services?',
          answer: 'The cost of my services primarily depends on the scope of the project and the specific needs of the client. As a freelance web developer, I strive to be adaptable and can work with various budgets while still delivering high-quality results. It\'s essential to understand that each project is unique, and my pricing structure reflects this by being flexible and tailored to your requirements. During our initial consultation, we\'ll discuss your project needs, and I\'ll provide a custom quote based on those factors. This approach allows me to offer fair pricing that accommodates your budget without compromising the quality and value of my expertise.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'What is your payment structure?',
          answer: 'My payment structure is designed to be flexible and accommodate the specific requirements of each client. I typically divide the project payment into two installments. The initial 50% is required upfront to start the design process, register any necessary domains, and set up web hosting and email servers as needed. It\'s important to note that not all clients may require these services, as some may already have their own domain or hosting provider. In such cases, the upfront payment will cover the design work and other project-specific tasks. The remaining 50% is due when the website is ready for launch, after all revisions have been completed on the development server. By tailoring my payment structure to the unique needs of each client, I ensure a fair and transparent pricing model that reflects the actual services provided.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'How long will it take to complete my project?',
          answer: 'Every web design project varies in duration, depending on its complexity and requirements. A typical brochure site takes approximately 6-8 weeks from start to finish, while a more complex build or e-commerce project could take anywhere from 8-20 weeks.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'Do you provide ongoing support and maintenance?',
          answer: 'Yes, I offer ongoing support and maintenance services to ensure your website remains up-to-date and functional. These services can be tailored to your specific needs and can be discussed during the initial consultation.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'Can you help with website content, such as copywriting and images',
          answer: 'Yes. While my primary focus is on web design and development, I can provide guidance on content creation and write copy tailored to your specific needs. If you lack photographers within my network to ensure your website has high-quality content.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'What platforms and technologies do you work with?',
          answer: 'I have experience working with a variety of platforms and technologies, including HTML, CSS, JavaScript, PHP, Magento, WordPress, Shopify, and more. I strive to stay updated on the latest web design trends and technologies to deliver the best possible solutions for my clients.',
          open: false,
          contentHeight: 0,
        },
        {
          question: 'How do you ensure my website will be user-friendly and accessible?',
          answer: ' I prioritise usability and accessibility when mydesigning websites, ensuring they are easy to navigate and compliant with accessibility guidelines. I also use responsive design techniques to ensure your website looks and functions well on all devices, including mobile phones and tablets.',
          open: false,
          contentHeight: 0,
        },
        

        // Add more FAQ items as needed
      ],
    };
  },
  methods: {
    updateContentHeight(index) {
      this.$nextTick(() => {
        const content = this.$refs.content[index];
        this.items[index].contentHeight = content.scrollHeight;
      });
    },
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.items.forEach((item, index) => {
          this.updateContentHeight(index);
        });
      },
    },
  },
  mounted() {
    this.items.forEach((item, index) => {
      this.updateContentHeight(index);
    });
  },
};
</script>
