import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    userToken: localStorage.getItem('userToken') || null, // Initialize from localStorage
    clientData: {
      loading: false, // Add a loading state
      data: null, // Actual client data
    },
  },
  mutations: {
    setToken(state, token) {
      state.userToken = token;
      localStorage.setItem('userToken', token); // Save token to localStorage
    },
    setLoading(state, isLoading) {
      state.clientData.loading = isLoading;
    },
    setClientData(state, data) {
      // Normalize data if needed
      state.clientData.data = Array.isArray(data) ? data[0] : data;
    },
  },
  actions: {
    async fetchClientData({ commit, state }, userEmail) {
      commit('setLoading', true); // Set loading state to true
      const wpApiUrl = 'https://wp.pixelcodelab.co.uk/wp-json/custom/v1/clients';
      try {
        const response = await axios.get(wpApiUrl, {
          params: { email: userEmail },
          headers: { 'Authorization': `Bearer ${state.userToken}` },
        });
        commit('setClientData', response.data);
      } catch (error) {
        console.error('Error fetching client data:', error);
        // Handle error appropriately
      } finally {
        commit('setLoading', false); // Set loading state back to false
      }
    },
  },
  getters: {
    clientData: (state) => state.clientData.data,
    clientWebsites: (state) =>
      state.clientData.data?.all_projects.map(
        (project) => project.clients_projects.website
      ) || [],
    clientContact: (state) => state.clientData.data?.contact || {},
    secondaryContacts: (state) =>
      state.clientData.data?.contact?.secondary_contacts || [],
  },
});
