<template>
  <div class="services">
    <div class="container mx-auto">
      <section class="freelance-section">
        <div class="flex flex-col sm:flex-row items-center">
          <div class="w-full md:w-1/3 text-center sm:text-left">
            <h2 class="freelance-title" style="padding:2% 4%;">
              Freelance Web designer
            </h2>
            
            <p class="class=font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-gray-400" style="text-align:
            left; padding: 7% 4%; color: white">
              I provide all-inclusive digital solutions. I create <strong>unique websites</strong>, develop secure e-commerce platforms, optimise sites for SEO, and offer reliable hosting services. My personalised approach ensures your online presence is <strong>impactful</strong> and <strong>effective.</strong>
            </p>
           
          </div>
          <!-- Banner right illustration -->
		<div class="w-full md:w-2/3 text-right float-right" style="display:flex;padding: 2% 4%;justify-content: center;">
	<img src="@/assets/images/synth2.png" alt="Developer" class="ship-animation" style="border-radius: 25px;" />
	
</div>
        </div>
        <div class="flex flex-col sm:flex-row items-center">
          <div class="w-full md:w-1/3 text-center sm:text-left">
            <h3 class="freelance-title-services" style="padding: 2% 4%">
              Services
            </h3>
           
          </div>
          <!-- Banner right illustration -->
		<div class="w-full md:w-2/3 text-right float-right" style="display:flex; justify-content: center;">
      
	
</div>
</div>
        <div class="service-container">
          
          <div class="w-full md:w-2/3 text-center sm:text-left">
          </div>
          <div class="w-full md:w-2/3 text-right float-right" style="display:flex; justify-content: center;"></div>

          <div class="services-grid">
            <div class="service-item">
              <a href="services/web-development">
                <div class="service-icon" style="color:blueviolet">
                  <i class="text-6xl bi bi-code-square"></i>
                </div>
                <h4 class="service-title">Web Design & Development</h4>
              </a>
            </div>
            <div class="service-item">
              <a href="services/ecommerce">
                <div class="service-icon" style="color:#d70067">
                  <i class="text-6xl bi bi-cart3"></i>
                </div>
                <h4 class="service-title">eCommerce Development</h4>
              </a>
            </div>
            <div class="service-item">
              <a href="services/seo">
                <div class="service-icon" style="color:#0e2b6b">
                  <i class="text-6xl bi bi-search"></i>
                </div>
                <h4 class="service-title">Search Engine Optimization</h4>
              </a>
            </div>
            <div class="service-item">
              <a href="services/hosting">
                <div class="service-icon" style="color:#fc878b">
                  <i class="text-6xl bi bi-server"></i>
                </div>
                <h4 class="service-title">Web Hosting & Maintenance</h4>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services',
};
</script>

<style scoped>
.freelance-title-services {
  font-size: 2rem;
  color: #fff
}
.services {
  background-color: #090335;
  padding: 5% 0;
}

.freelance-section {
  margin: 5% 0;
}

.freelance-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color:#ddd
}

.freelance-description {
  font-size: 1.25rem;
  color: #ddd;
  margin-bottom: 1rem;
  
}

.service-container {
  text-align: left;
}

.services-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ddd;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.service-item {
  flex: 1 1 200px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5rem;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}

.service-item:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.service-icon {
  margin-bottom: 1rem;
}

.service-title {
  font-size: 1.4rem;
  font-weight: 500;
  color:#ddd;
}

@media screen and (min-width: 768px) {
  .freelance-title,
  .freelance-description {
    text-align: left;
  }
  .service-container {
    padding: 2% 4%;
  }
}
@keyframes rotate-scale-up {
    50% {
        transform: scale(1.1) rotate(5deg);
    }
}
.service-item:hover .service-icon i {
    animation: rotate-scale-up 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    color: #00a3ff;
}
</style>
