<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyright.vue';
import { socialLinks } from '../../data/socialLinks';



export default {
	components: 
	{ FooterCopyright },
	data() {
		return {
			socials: socialLinks,
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>
<template>
	<div class="footer-colour" style="background-color:#090335;">
	<div class="container mx-auto">
    <div class="pt-10 sm:pt-16">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 class="font-general-semibold text-3xl md:text-3xl xl:text-4xl leading-9 sm:text-left  dark:text-primary-light fadeInUp" style="color:white">Let's create something <span>beautiful</span> together!</h2>
        </div>
        <div>
			<h3 class="text-base sm:text-2xl font-semibold text-left" style="color:white">Ready to bring your ideas to life?</h3>
          <p class="text-left my1" style="color:white;padding:5% 0">
            Since you've scrolled all the way down here, how about dropping me an email?<br><a class="underline text-base sm:text-2xl font-semibold text-left text-ternary-dark hover:text-indigo-500 transition duration-500 ease-in-out" style="color:white; padding: 2% 0;" href="/contact">Let's work together</a>

          </p>
        </div>
      </div>
    </div>
	</div>
	<div class="container mx-auto">
		<div
			class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark"
		>

		
		</div>
	</div>
</div>

  <div class="footer-colour" style="background-color:#090335;">
    <div class="container mx-auto ">
      <div class="footer-container">

        <!-- Service areas -->
        <div class="footer-column service-areas">
          <h2 class="font-general-semibold text-3xl md:text-3xl xl:text-4xl leading-9 sm:text-left dark:text-primary-light" style="color:white">
            Service Area
          </h2>
		<p class="text-left my1" style="color:white;padding:5% 0;">I'm a freelance web designer and developer operating in Devon. Additionally, I have the flexibility to work remotely, catering to clients across the entire country. If you have a project on the horizon that you'd like to discuss, please don't hesitate to reach out</p>
        </div>

        <!-- Navigation -->
        <div class="footer-column navigation">
			<h3 class="text-base sm:text-2xl font-semibold text-left" style="color:white">Navigation</h3>
          <ul style="text-align: left;">
            <li><a href="/" style="color:white">Home</a></li>
            <li><a href="/portfolio" style="color:white">Portfolio</a></li>
            <li><a href="/about" style="color:white">About</a></li>
            <li><a href="/journal" style="color:white">Journal</a></li>
            <li><a href="/client-login" style="color:white">Client Login</a></li>
            <li><a href="/contact" style="color:white">Contact</a></li>
          </ul>
        </div>

        <!-- Services -->
        <div class="footer-column services">
          <h3 class="text-base sm:text-2xl font-semibold text-left" style="color:white">Services</h3>
          <ul style="text-align: left;">
            <li style="color:white"><a href="/services/web-development">Web Design</a></li>
            <li style="color:white"><a href="/services/ecommerce">eCommerce</a></li>
            <li style="color:white"><a href="/services/seo">SEO</a>SEO</li>
            <li style="color:white"><a href="/services/hosting">Web Hosting</a></li>
            <li style="color:white"><a href="/services/hosting">Maintenance</a></li>
          </ul>
        </div>

        <!-- Contact -->
        <div class="footer-column contact">
          <h3 class="text-base sm:text-2xl font-semibold text-left" style="color:white">Contact</h3>
          <p class="text-left my1" style="color:white;padding:5% 0">
            <a class="underline text-base sm:text-2xl font-semibold text-left text-ternary-dark hover:text-indigo-500 transition duration-500 ease-in-out" style="color:white; padding: 2% 0;" href="mailto:luke@pixelcodelab.co.uk">luke@pixelcodelab.co.uk</a>
          </p>

          <p class="follow-me" style="color:white; font-weight:bold; text-align: left;">Social</p>
          <ul class="flex gap-4 sm:gap-8">
            <a
              v-for="social in socials"
              :key="social.id"
              :href="social.url"
              target="__blank"
              class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-500"
            >
              <i
                :data-feather="social.icon"
                class="w-5 sm:w-8 h-5 sm:h-8"
              ></i>
            </a>
          </ul>
        </div>

      </div>
    </div>
    <!-- Footer copyright -->
    <div class="container mx-auto">
      <FooterCopyright />
    </div>
  </div>
</template>

<style scoped>
h2 span {
background-image: linear-gradient(180deg,#b32bce 0%,#FF6373 100%);
    background-position: 0 89%;
    transition: background-size 0.8s ease-in-out;
    margin: 0 -3px;
    padding: 0 3px;
    display: initial;
    background-size: 100% 18%!important;
    transition-delay: 0.4s!important;
    background-repeat: no-repeat;
}
.footer-container {
  display: flex;
  flex-wrap: wrap;
}

.footer-column {
  padding: 1em;
}

.service-areas {
  flex: 1 1 30%;
}

.navigation,
.services,
.contact {
  flex: 1 1 20%;
}

.column-title {
  font-weight: bold;
  margin-bottom: 1em;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .footer-column {
    flex: 1 1 100%;
  }
}
</style>
