<script>
import feather from 'feather-icons';
import Rellax from 'rellax';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
			backgroundImage: require('@/assets/images/Hillscape.png'),
			
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
        feather.replace();
        this.theme = localStorage.getItem('theme') || 'light';
        new Rellax('.rellax', {
            speed: -1
        });

		document.addEventListener("scroll", () => {
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			let sun = document.querySelector('.sun');

			if (scrollTop > 0) {
				let percentage = scrollTop / (document.documentElement.scrollHeight - window.innerHeight);

				let bottomPosition = 10 + (percentage * 50) + '%';
				let bgColor = `rgba(255, 100, 50, ${percentage})`;

				sun.style.bottom = bottomPosition;
				document.querySelector('.section__hero__bg__back').style.backgroundColor = bgColor;
			}
		});
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
	class="flex flex-col sm:justify-between items-center sm:flex-row"
	style="padding: 2% 7%;"
>
		<!-- Banner left contents -->
		<div class="w-full md:w-2/3 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-5xl text-center sm:text-left text-ternary-dark dark:text-primary-light fadeInUp" data-te-animation-start="onLoad" 
			style="color: white;
  padding: 2% 0px;;"
				>
				Hi. I'm Luke! I design stunning websites			</h1>
				<p class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-gray-400">
    I'm an independent web designer and developer. For the past decade, I've been dedicated to breaking new ground in the realm of digital design.
</p>
			<div class="flex justify-center sm:block">
				<a
					
					href="/projects"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
					aria-label="Download Resume"
					style="background-image:linear-gradient(51deg,#39007D 0%,#BC329A 40%,#FF6373 80%)"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
						style="color:white"
					></i>
					<span
						class="text-sm sm:text-lg font-general-medium duration-100"
						style="color:white"
						>View Work</span
					></a
				>
			</div>
		</div>

		<!-- Banner right illustration -->
		<div class="w-full md:w-2/3 text-right float-right" style="display:flex; justify-content: center;">
	<img v-if="theme === 'light'" src="@/assets/images/ship_1.png" alt="Developer" class="ship-animation" style="border-radius: 25px;" />
	<img v-else src="@/assets/images/ship_1.png" alt="Developer" class="ship-animation" style="border-radius: 25px;" />
</div>
		
	</section>
	
	<section class="section section__hero in-view">
	
        <div class="section__hero__bg">
			
            <div class="section__hero__bg__back rellax" style="background-color: transparent;">
                <img
                    src="@/assets/images/cityscape.png"
                    alt="background"
                    style="display:block; width:100%; height: 100%;"
                />
				<div class="suns">
                <div class="sun"></div>
                <div class="sun"></div>
                <div class="sun"></div>
                <div class="sun"></div>
				<div class="sun"></div>
                <div class="sun"></div>
                <div class="sun"></div>
				<div class="sun"></div>
                <div class="sun"></div>
                <div class="sun"></div>
				<div class="sun"></div>
                <div class="sun"></div>
                <div class="sun"></div>
                <!-- Add more moon divs here as needed -->
            </div>
				<div class="clouds"></div>
				<div class="neon-light-1">
					ピクセル コード ラボ
</div>
<div class="neon-light-2">
	サイバー
</div>
<div class="antenna">
	<div class="glowing-flash"></div>
</div>

				<div class="flying-cars">
				<div class="flying-car flying-car-1"></div>
    <div class="flying-car flying-car-2"></div>
	<div class="flying-car flying-car-3"></div>
<div class="flying-car flying-car-4"></div>
<div class="flying-car flying-car-5"></div>
<div class="flying-car flying-car-6"></div>
<div class="flying-car flying-car-7"></div>
<div class="flying-car flying-car-8"></div>
	<div class="flying-car flying-car-9"></div>
	<div class="flying-car flying-car-10"></div>
	<div class="flying-car flying-car-11"></div>
	<div class="flying-car flying-car-12"></div>
    <div class="flying-car flying-car-13"></div>
    <div class="flying-car flying-car-14"></div>
    <div class="flying-car flying-car-15"></div>
</div>

            </div>
			
            <div class="section__hero__bg__front">
                <img
                    src="@/assets/images/Hillscape.png"
                    alt="foreground"
                    style="display:block; width:100%; height:100%; margin-top: 1%;"
                />
            </div>
        </div>
    </section>
</template>

<style scoped>
h1 {
	font-size: 4rem;
}
.font-general-medium {
            text-align: left;
			color:#fff
        }
@media (max-width: 767px) {
        h1 {
            line-height: 1.2; /* Adjust the line-height value as needed */
			font-size: 2rem;
			text-align: left;
        }
		.font-general-medium {
            text-align: left;
        }
		.flex-col {
			flex-direction: column-reverse;
		}
    }
	
.section__hero__bg__back {
    background-color: transparent !important;
    transform: none !important;
	background-color: transparent !important;
    transform: none !important;
}
.antenna {
  position: relative;
  z-index: 999;
  top:-67%;
  left: -12.3%;
  /* Other styles for the antenna */
}
.glowing-flash {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  opacity: 0;
  box-shadow: 0 0 10px 5px rgb(255, 255, 255);
  animation: glow 1s infinite;
}

.antenna::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  opacity: 0;
  box-shadow: 0 0 10px 5px red;
  animation: glow 1s infinite;
}

@keyframes glow {
  0% {
    opacity: 0;
    box-shadow: 0 0 10px 5px red;
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 20px 10px red;
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 10px 5px red;
  }
}
.neon-light-1 {
    font-size: 24px;
    color: #ffd7006b;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation 1.5s infinite alternate;
    z-index: 5;
	position: absolute;
    top: 30%; /* Adjust this as needed */
    left: 67.5%; /* Adjust this as needed */
	writing-mode: vertical-lr;
	transform: skewY(10deg)
}
.neon-light-2 {
	font-size: 20px;
    color: #04104df2;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation-99c0ec1a 1.5s infinite alternate;
    z-index: 5;
    position: absolute;
    top: 43%;
    left: 76.5%;
    transform: skewY(5deg);
}
@media (max-width: 768px) {
  /* Adjust the maximum width as per your needs */
  .neon-light-1 {
	font-size: 10px;
    color: #ffd7006b;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation-99c0ec1a 1.5s infinite alternate;
    z-index: 5;
    position: absolute;
    top: 30%;
    left: 66.5%;
    writing-mode: vertical-lr;
    transform: skewY(10deg);
}
.neon-light-2 {
    font-size: 8px;
    color: #04104df2;
    text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    animation: flickerAnimation-99c0ec1a 1.5s infinite alternate;
    z-index: 5;
    position: absolute;
    top: 43%;
    left: 76.5%;
    transform: skewY(5deg);
}
  }


@keyframes flickerAnimation {
    0% {
        opacity: 1;
        text-shadow: 0 0 5px #4ff, 0 0 10px #4ff, 0 0 20px #4ff, 0 0 30px #4ff, 0 0 40px #4ff;
    }
    100% {
        opacity: 0.7;
        text-shadow: none;
    }
}
.suns {
    position: absolute;
    bottom: 10%;
    left: 50%;
    width: 100px;
    height: 100px;
    z-index: -1;
}

.sun {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: radial-gradient(circle, #FFD700, #FF8C00);
    opacity: 0.2;
    transition: all 2s;
}

.suns .sun:nth-child(2) {
    width: 150px;
    height: 150px;
    opacity: 0.15;
    left: -25px;
    bottom: -25px;
}

.suns .sun:nth-child(3) {
    width: 250px;
    height: 250px;
    opacity: 0.1;
    left: -75px;
    bottom: -75px;
}

.suns .sun:nth-child(4) {
    width: 350px;
    height: 350px;
    opacity: 0.05;
    left: -125px;
    bottom: -125px;
}

.section__hero {
    position: relative;
    overflow: hidden;
    height: 1100px; /* Default height for larger screens */
}

@media (max-width: 768px) {
  /* Adjust the maximum width as per your needs */
  .section__hero {
    height: auto; /* Set height to auto for mobile devices */
  }
}

.section__hero__bg {
    position: relative;
    height: 100%;
}

.section__hero__bg__front, .section__hero__bg__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.section__hero__bg__front {
    z-index: 2;
}

.section__hero__bg__back {
    z-index: 1;
	position: relative;
}
@keyframes bobbing {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-30px);
	}
	100% {
		transform: translateY(0);
	}
}

.ship-animation {
	animation: bobbing 5s infinite ease-in-out;
}
.flying-car {
    position: absolute;
    width: 5px;
    height: 2px;
    background: rgb(255, 255, 255); /* You can change this to an image of a car */
    border-radius: 5px;
}

.flying-car-1 {
    animation: flying-animation-1 10s linear infinite;
	z-index:-1;
}

.flying-car-2 {
    animation: flying-animation-2 8s linear infinite;
	z-index: -1;
}

.flying-car-3 {
    animation: flying-animation-3 12s linear infinite;
	z-index: -1;
}

.flying-car-4 {
    animation: flying-animation-4 6s linear infinite;
}
.flying-car-5 {
    animation: flying-animation-5 10s linear infinite;
    z-index: -1; /* This will be behind of the cityscape */
}

.flying-car-6 {
    animation: flying-animation-6 9s linear infinite;
    z-index: 2; /* This will be in front of the cityscape */
}

.flying-car-7 {
    animation: flying-animation-7 11s linear infinite;
    z-index: -1; /* This will be behind the cityscape */
}
.flying-car-8 {
    animation: flying-animation-8 6s linear infinite;
	z-index: 2;
}

.flying-car-9 {
    animation: flying-animation-9 4s linear infinite;
	z-index: 3;
}

.flying-car-10 {
    animation: flying-animation-10 5s linear infinite;
	z-index: 4;
}

.flying-car-11 {
    animation: flying-animation-11 4.5s linear infinite;
	z-index: 5;
}

.flying-car-12 {
    animation: flying-animation-12 4s linear infinite;
	z-index: 6;
}

.flying-car-13 {
    animation: flying-animation-13 3.5s linear infinite;
	z-index: 7;
}

.flying-car-14 {
    animation: flying-animation-14 3s linear infinite;
	z-index: -1;
}

.flying-car-15 {
    animation: flying-animation-15 2.5s linear infinite;
	z-index: -1;
}


@keyframes flying-animation-1 {
    0% {
        left: -50px;
        top: 600px;
    }
    50% {
        left: 50%;
        top: 600px;
    }
    100% {
        left: 100vw;
        top: 600px;
    }
}

@keyframes flying-animation-2 {
    0% {
        left: -50px;
        top: 450px;
    }
    50% {
        left: 50%;
        top: 450px;
    }
    100% {
        left: 100vw;
        top: 450px;
    }
}

@keyframes flying-animation-3 {
    0% {
        left: -50px;
        top: 575px;
    }
    50% {
        left: 50%;
        top: 575px;
    }
    100% {
        left: 100vw;
        top: 575px;
    }
}

@keyframes flying-animation-4 {
    0% {
        left: 100vw;
        top: 725px;
    }
    50% {
        left: 50%;
        top: 725px;
    }
    100% {
        left: -50px;
        top: 725px;
    }
}
@keyframes flying-animation-5 {
    0% {
        left: -50px;
        top: 700px;
    }
    50% {
        left: 50%;
        top: 700px;
    }
    100% {
        left: 100vw;
        top: 700px;
    }
}

@keyframes flying-animation-6 {
    0% {
        left: 100vw;
        top: 750px;
    }
    50% {
        left: 50%;
        top: 750px;
    }
    100% {
        left: -50px;
        top: 750px;
    }
}

@keyframes flying-animation-7 {
    0% {
        left: -50px;
        top: 800px;
    }
    50% {
        left: 50%;
        top: 800px;
    }
    100% {
        left: 100vw;
        top: 800px;
    }
}
@keyframes flying-animation-8 {
    0% {
        left: -50px;
        top: 650px;
    }
    50% {
        left: 50%;
        top: 650px;
    }
    100% {
        left: 100vw;
        top: 650px;
    }
}

@keyframes flying-animation-9 {
    0% {
        left: 100vw;
        top: 850px;
    }
    50% {
        left: 50%;
        top: 875px;
    }
    100% {
        left: -50px;
        top: 850px;
    }
}

@keyframes flying-animation-10 {
    0% {
        left: -50px;
        top: 875px;
    }
    50% {
        left: 50%;
        top: 875px;
    }
    100% {
        left: 100vw;
        top: 875px;
    }
}

@keyframes flying-animation-11 {
    0% {
        left: 100vw;
        top: 880px;
    }
    50% {
        left: 50%;
        top: 880px;
    }
    100% {
        left: -50px;
        top: 880px;
    }
}
@keyframes flying-animation-12 {
    0% {
        left: -50px;
        top: 890px;
    }
    50% {
        left: 50%;
        top: 890px;
    }
    100% {
        left: 100vw;
        top: 890px;
    }
}

@keyframes flying-animation-13 {
    0% {
        left: 100vw;
        top: 900px;
    }
    50% {
        left: 50%;
        top: 900px;
    }
    100% {
        left: -50px;
        top: 990px;
    }
}

@keyframes flying-animation-14 {
    0% {
        left: -50px;
        top: 910px;
    }
    50% {
        left: 50%;
        top: 910px;
    }
    100% {
        left: 100vw;
        top: 910px;
    }
}

@keyframes flying-animation-15 {
    0% {
        left: 100vw;
        top: 900px;
    }
    50% {
        left: 50%;
        top: 900px;
    }
    100% {
        left: -50px;
        top: 900px;
    }
}
@media (max-width: 768px) {
  /* Adjust the maximum width as per your needs */
  @keyframes flying-animation-1 {
    0% {
        left: -50px;
        top: 50px;
    }
    50% {
        left: 50%;
        top: 50px;
    }
    100% {
        left: 100vw;
        top: 50px;
    }
}

@keyframes flying-animation-2 {
    0% {
        left: -50px;
        top: 65px;
    }
    50% {
        left: 50%;
        top: 65px;
    }
    100% {
        left: 100vw;
        top: 65px;
    }
}

@keyframes flying-animation-3 {
    0% {
        left: -50px;
        top: 80px;
    }
    50% {
        left: 50%;
        top: 80px;
    }
    100% {
        left: 100vw;
        top: 80px;
    }
}

@keyframes flying-animation-4 {
    0% {
        left: 100vw;
        top: 100px;
    }
    50% {
        left: 50%;
        top: 100px;
    }
    100% {
        left: -50px;
        top: 100px;
    }
}
@keyframes flying-animation-5 {
    0% {
        left: -50px;
        top: 120px;
    }
    50% {
        left: 50%;
        top: 120px;
    }
    100% {
        left: 100vw;
        top: 120px;
    }
}

@keyframes flying-animation-6 {
    0% {
        left: 100vw;
        top: 130px;
    }
    50% {
        left: 50%;
        top: 130px;
    }
    100% {
        left: -50px;
        top: 130px;
    }
}

@keyframes flying-animation-7 {
    0% {
        left: -50px;
        top: 140px;
    }
    50% {
        left: 50%;
        top: 140px;
    }
    100% {
        left: 100vw;
        top: 140px;
    }
}
@keyframes flying-animation-8 {
    0% {
        left: -50px;
        top: 160px;
    }
    50% {
        left: 50%;
        top: 160px;
    }
    100% {
        left: 100vw;
        top: 160px;
    }
}

@keyframes flying-animation-9 {
    0% {
        left: 100vw;
        top: 170px;
    }
    50% {
        left: 50%;
        top: 170px;
    }
    100% {
        left: -50px;
        top: 170px;
    }
}

@keyframes flying-animation-10 {
    0% {
        left: -50px;
        top: 175px;
    }
    50% {
        left: 50%;
        top: 175px;
    }
    100% {
        left: 100vw;
        top: 175px;
    }
}

@keyframes flying-animation-11 {
    0% {
        left: 100vw;
        top: 180px;
    }
    50% {
        left: 50%;
        top: 180px;
    }
    100% {
        left: -50px;
        top: 180px;
    }
}
@keyframes flying-animation-12 {
    0% {
        left: -50px;
        top: 183px;
    }
    50% {
        left: 50%;
        top: 183px;
    }
    100% {
        left: 100vw;
        top: 183px;
    }
}

@keyframes flying-animation-13 {
    0% {
        left: 100vw;
        top: 190px;
    }
    50% {
        left: 50%;
        top: 190px;
    }
    100% {
        left: -50px;
        top: 190px;
    }
}

@keyframes flying-animation-14 {
    0% {
        left: -50px;
        top: 190px;
    }
    50% {
        left: 50%;
        top: 190px;
    }
    100% {
        left: 100vw;
        top: 190px;
    }
}

@keyframes flying-animation-15 {
    0% {
        left: 100vw;
        top: 190px;
    }
    50% {
        left: 50%;
        top: 190px;
    }
    100% {
        left: -50px;
        top: 190px;
    }
}
}

</style>
