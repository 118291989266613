
<template>
    <div class="backyboi">
		<AppHeader />
	</div>
    <div class="container mx-auto">
    <section class="p-4">
        <h1 class="text-4xl font-bold text-center my-8">My Services</h1>
      
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        
            <div class="service-card">
                <h2 class="text-2xl font-bold mb-4">Web Design</h2>
                <p class="mb-4">Creative and intuitive website designs that leave a lasting impression and enhance user experience.</p>
            </div>
  
            <div class="service-card">
                <h2 class="text-2xl font-bold mb-4">Web Development</h2>
                <p class="mb-4">Robust and scalable web development solutions, ensuring your website is functional, secure and fast.</p>
            </div>
  
            <div class="service-card">
                <h2 class="text-2xl font-bold mb-4">E-commerce</h2>
                <p class="mb-4">Streamlined and secure e-commerce platforms that drive sales and provide a seamless user experience.</p>
            </div>
  
            <div class="service-card">
                <h2 class="text-2xl font-bold mb-4">SEO</h2>
                <p class="mb-4">Comprehensive SEO strategies to improve your website's visibility and rank higher in search engine results.</p>
            </div>
  
            <div class="service-card">
                <h2 class="text-2xl font-bold mb-4">Hosting</h2>
                <p class="mb-4">Reliable and secure hosting services ensuring your website is always accessible and performs optimally.</p>
            </div>
  
        </div>
    </section>
    </div>
</template>
  

<script>
import AppHeader from '@/components/shared/AppHeader.vue'

export default {
    name: "MyServices",
    components: {
		AppHeader,
	},
}
</script>
  
<style scoped>
.service-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
}

.service-card h2 {
    color: #3B82F6;
}
.backyboi {
	background-image: linear-gradient(180deg, #39007D 0%, #4b0080 13%,  #540082 29%, #650086 46%, #FF6373 100% );
}
</style>
