// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		slug: "pearl-gallery",
		title: 'Pearl Gallery',
		category: 'Ecommerce Website',
		img: require('@/assets/images/Pearl_Gallery/PG_1.jpg'),
		routeName: 'pearl-gallery'
	},
	{
		id: 2,
		slug: "kaztwithak",
		title: 'Katz With A K',
		category: 'UI/UX Design',
		img: require('@/assets/images/katz-tv.jpg'),
	},
	/* {
		id: 3,
		slug: "wvm-studio",
		title: 'WVM Studio',
		category: 'Internationally acclaimed Designer',
		img: require('@/assets/images/wayne.jpg'),
	},
	*/
	{
		id: 4,
		slug: "ellison-davies",
		title: 'Ellison Davies Fine Jewellery',
		category: 'Ecommerce Website',
		img: require('@/assets/images/Ellison_Davies/ED_2.jpg'),
	},
	{
		id: 5,
		slug: "relax-at-the-bothy",
		title: 'The Bothy Devon',
		category: 'UI/UX Design',
		img: require('@/assets/images/The_Bothy/The_Bothy.png'),
	},
	{
		id: 6,
		slug: "heating-and-plumbing",
		title: 'CMS Heating & Plumbing',
		category: 'UI/UX Design',
		img: require('@/assets/images/CMS/CMS_Brand.jpg'),
	},
	{
		id: 7,
		slug: "belluno",
		title: 'Belluno Bar & Grill',
		category: 'Web Application',
		img: require('@/assets/images/belluno.jpg'),
	},
	{
		id: 8,
		slug: "shopping-list-app",
		title: 'Shopping List App',
		category: 'Web Application',
		img: require('@/assets/images/shopping.jpg'),
	},
	{
		id: 9,
		slug: "modern-legacy-moto",
		title: 'Modern Legacy Moto',
		category: 'UI/UX Design',
		img: require('@/assets/images/enfield.jpg'),
	},
	{
		id: 9,
		slug: "tarka-chimney",
		title: 'Tarka Chimney Sweeps',
		category: 'UI/UX Design',
		img: require('@/assets/images/tarka.jpg'),
	},
];

export default projects;
