import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCr2sMLeCTNUXiC2eazzORtKEUMQjQHo8M",
  authDomain: "pixelcodelab.firebaseapp.com",
  projectId: "pixelcodelab",
  storageBucket: "pixelcodelab.appspot.com",
  messagingSenderId: "187276891717",
  appId: "1:187276891717:web:aa85721ade24193d6c352c",
  measurementId: "G-3FS88209NV"
};

// Initialize Firebase without assigning to a variable
initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore();

export { auth, db };
