<script>
export default {
	props: ['showModal', 'isOpen'],
	methods: {
		makePhoneCall() {
			window.location.href = 'tel:07980060489';
		},
	},
};
</script>

<template>
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
	>
		<router-link
			to="/projects"
			class="nav-link font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light sm:mx-4 mb-2 sm:py-2"
			aria-label="Projects"
			style="color:white;"
			>Portfolio</router-link
		>
		<router-link
			to="/about"
			class="nav-link font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light sm:mx-4 mb-2 sm:py-2"
			aria-label="About"
			style="color:white;"
			>About</router-link
		>
		<router-link
			to="/services"
			class="nav-link font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light sm:mx-4 mb-2 sm:py-2"
			aria-label="About"
			style="color:white;"
			>Services</router-link
		>
		
		<router-link
			to="/journal"
			class="nav-link font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light sm:mx-4 mb-2 sm:py-2"
			aria-label="Journal"
			style="color:white;"
			>Journal</router-link
		>
		<router-link
			to="/contact"
			class="nav-link font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light sm:mx-4 mb-2 sm:py-2"
			aria-label="Contact"
			style="color:white;"
			>Contact</router-link
		>
		
		<div
	class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark flex justify-start space-x-4"
>
	
<button

	class="font-general-medium sm:hidden block text-left text-md font-medium custom-gradient text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-500"
	@click="$router.push('/login')"
	aria-label="Client Login Button"
>
	Client Login
</button>

<button
    id="grad"
	class="font-general-medium sm:hidden block text-left text-md font-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-500"
	@click="makePhoneCall()"
	aria-label="Call Me Button"
>
	Call Me
</button>

</div>
		
	</div>
</template>


<style scoped>
.nav-link {
	
    color: white;
    position: relative;
    display: inline-block;
    transition: color 0.3s, transform 0.3s;
}

.nav-link:hover {
    color: #3490dc;
    transform: scale(1.1);
}

.nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #3490dc;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.nav-link:hover::before {
    visibility: visible;
    width: 100%;
}

.nav-link:hover {
    transform: rotate(5deg);
}
</style>
