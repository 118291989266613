import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import { auth } from '../firebase';  // Make sure this path is correct!
import MyServices from '@/components/services/MyServices.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'PixelCodeLab - Home',
		},
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: {
			title: 'PixelCodeLab - About',
		},
	},
	{
		path: '/journal',
		name: 'Journal',
		component: () => import(/* webpackChunkName: "about" */ '../views/Journal.vue'),
		meta: {
			title: 'PixelCodeLab - Journal',
		},
	},
	{
		path: '/projects',
		name: 'Projects',
		component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
		meta: {
			title: 'PixelCodeLab - Projects',
		},
	},
	{
		path: '/services',
		name: 'MyServices',
		component: () => import(/* webpackChunkName: "projects" */ '@/components/services/MyServices.vue'),
		meta: {
			title: 'PixelCodeLab - Services',
		},
	},
	{
		path: '/pricing',
		name: 'Pricing',
		component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
		meta: {
			title: 'PixelCodeLab - Pricing',
		},
	},
	{
		path: '/projects/:routeName',
		name: 'SingleProject',
		component: () => import(/* webpackChunkName: "single-project" */ '../views/Projects/ProjectLoader.vue'),
		props: true
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
		meta: {
			title: 'PixelCodeLab - Contact',
		},
		
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../components/backend/Login.vue'),
		meta: {
			title: 'PixelCodeLab - Login',
		},
	},
	{
		path: '/services',
		name: 'Services',
		component: MyServices,
		
	},
	{
		path: '/panel',
		name: 'CustomerPanel',
		component: () => import('../components/backend/CustomerPanel.vue'),
		meta: {
			title: 'PixelCodeLab - Customer Panel',
			requiresAuth: true,
		},
	}
	
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView();
	},
});

router.beforeEach((to, from, next) => {
	const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
	const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
	const previousNearestWithMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

	if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
	else if(previousNearestWithMeta) document.title = previousNearestWithMeta.meta.title;

	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

	if(!nearestWithMeta) return next();

	nearestWithMeta.meta.metaTags.map((tagDef) => {
		const tag = document.createElement('meta');
		Object.keys(tagDef).forEach((key) => {
			tag.setAttribute(key, tagDef[key]);
		});
		tag.setAttribute('data-vue-router-controlled', '');
		return tag;
	}).forEach((tag) => document.head.appendChild(tag));

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const isAuthenticated = auth.currentUser;

	if(requiresAuth && !isAuthenticated) next('/login');
	else next();
	
});

export default router;
