<script>
import HireMeModal from '../HireMeModal.vue';
import feather from 'feather-icons';
import AppHeaderLinks from './AppHeaderLinks.vue';

export default {
	components: {
		
		HireMeModal,
		AppHeaderLinks,
		
	},
	data() {
		return {
			isOpen: false,
			theme: '',
			modal: false,
			categories: [
				{
					id: 1,
					value: 'web',
					name: 'Web Application',
				},
				{
					id: 2,
					value: 'mobile',
					name: 'Mobile Application',
				},
				{
					id: 3,
					value: 'ui-ux',
					name: 'UI/UX Design',
				},
				{
					id: 4,
					value: 'branding',
					name: 'Branding & Anim',
				},
			],
		};
	},

	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
		this.$createStars();
	},
	methods: {
		updateTheme(theme) {
			this.theme = theme;
		},
		showModal() {
			if (this.modal) {
				// Stop screen scrolling
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
		},
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div id="stars-container"></div>
	<nav id="nav">
		<!-- Header start -->
		<div class="z-10 w-full flex flex-col md:flex-row justify-between items-center my-6 px-4 sm:px-0">
			<!-- Header logos -->
			<div class="mb-4 md:mb-0">
				<router-link to="/">
					<img
						v-if="theme === 'light'"
						src="@/assets/images/PixelCodeLab_3.png"
						style="width: 8rem;"
						alt="Dark Logo"
					/>
					
				</router-link>
			</div>

			<!-- Header menu links and small screen hamburger menu -->
			<div class="flex flex-col md:flex-row justify-between items-center w-full">
				<div class="flex justify-between items-center w-full md:w-auto">
					<!-- Theme switcher small screen -->
					<theme-switcher
						:theme="theme"
						@themeChanged="updateTheme"
						class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg hidden"
					/>

					<!-- Small screen hamburger menu -->
					<div class="sm:hidden">
						<button
							@click="isOpen = !isOpen"
							type="button"
							class="focus:outline-none"
							aria-label="Hamburger Menu"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
							>
								<path
									v-if="isOpen"
									fill-rule="evenodd"
									d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
									clip-rule="evenodd"
								></path>
								<path
									v-if="!isOpen"
									fill-rule="evenodd"
									d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
								></path>
							</svg>
						</button>
					</div>
				</div>

				<!-- Header links -->
				<AppHeaderLinks :showModal="showModal" :isOpen="isOpen" />

				<!-- Header right section buttons -->
				<div class="hidden sm:flex justify-between items-center flex-col md:flex-row">
					<div class="hidden md:block" style="margin-right: 1pc;">
						<a href="/login">
							<button
								style="background-image: linear-gradient(51deg, rgb(57, 0, 125, 0.3) 0%, rgb(50, 105, 188) 40%, rgb(99, 160, 255) 80%);"
								title="Client Login"
								class="text-md font-general-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
								aria-label="Client Login"
							>
								<i data-feather="user" class="icon mr-0"></i>
								<span class="hidden lg:inline-block"></span>
							</button>
						</a>
					</div>

					<div class="hidden md:block mt-4 md:mt-0"></div>

					<div class="hidden md:block" style="margin-right: 1pc;">
						<a href="tel:07980060489">
							<button
								title="Call Me"
								class="text-md font-general-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
								aria-label="Call Me Button"
							>
								<i data-feather="phone" class="icon mr-0"></i>
								<span class="hidden lg:inline-block"></span>
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
		
		<!-- Hire me modal -->
		<HireMeModal
			:showModal="showModal"
			:modal="modal"
			:categories="categories"
			aria-modal="Hire Me Modal"
		/>
	</nav>
</template>


<style scoped>
#nav {
    width: 100%;
    padding: 1% 3%;
}

#nav a.router-link-exact-active {
    @apply text-indigo-700;
    @apply dark:text-indigo-400;
    @apply font-medium;
}

.icon {
    display: inline-block;
    vertical-align: middle;
}
</style>
